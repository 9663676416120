import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { State as SeasonPassState} from 'src/app/interfaces/State/SeasonPass/state';
import { SeasonPassService } from 'src/app/services/SeasonPass/season-pass.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent {
  public seasonPasses: SeasonPassState = {
    seasonPasses: undefined,
  }

  private _subscription?: Subscription

  constructor(
    private _seasonPassService: SeasonPassService,
    private _store: Store,
  ) {
  }

  ngOnInit(): void {
    this._seasonPassService.getSeasonPasses()

    this._subscription = this._store.subscribe((state: any) => {

      this.seasonPasses = {
        ...state?.seasonPasses,
        seasonPasses: state?.seasonPasses?.seasonPasses?.filter((seasonPass: any) => seasonPass.list_type == 'season-pass')
      }
    })
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }
}
