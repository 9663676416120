<div class="container pt-10">
    <div class="flex justify-between sm:flex-col">
        <div class="mb-5 w-[75%] sm:w-full">
            <div *ngIf="hasCurrentSeasonPasses()">
                <div id="current-tickets" class=" mb-7">
                    <div class=" mb-3" *ngFor="let seasonPass of seasonPasses.current | keyvalue">
                        <app-my-season-pass [auth]="auth" [seasonPassWrapperObject]="seasonPass.value"></app-my-season-pass>
                    </div>
                </div>
            </div>
            <div *ngIf="hasExpiredSeasonPasses()"> 
                <h2 class="text-white text-[15px] font-bold mb-5">{{ 'my-season-passes.my-past-season-passes' | translate }}</h2>
                <div id="current-tickets" class=" rounded-[10px] mb-3">
                    <div class="rounded-[10px] mb-3" *ngFor="let seasonPass of seasonPasses.expired | keyvalue ">
                        <app-my-season-pass [auth]="auth" [seasonPassWrapperObject]="seasonPass.value"></app-my-season-pass>
                    </div>
                </div>
            </div>
        </div>
        <section class="w-[20%] sm:w-full" *ngIf="!mapOnly && advertisementUrl">
            <img [src]="advertisementUrl" [alt]="'homepage.advert' | translate" class="rounded-[20px]">
        </section>
    </div>
</div>