<div
    class="p-10 drop-shadow-[0_0_25px_rgba(0,0,0,1)] bg-white relative mt-[10vh] rounded-[10px] w-[600px] max-w-[90vw] sm:!mt-[60px] max-h-[84vh] overflow-y-scroll">
    <button (click)="hideModal()" class="absolute top-[30px] right-[30px]" type="button" id="closeButton" role="button">
        <app-x></app-x>
    </button>
    <h1 class="text-[15px] font-bold" *ngIf="ticket">{{ 'edit-ticket-type.edit-ticket-type' | translate:{title: ticket.title} }}</h1>
    <h1 class="text-[15px] font-bold" *ngIf="seasonPass">{{ 'edit-ticket-type.edit-season-pass-type' | translate:{title: seasonPass.title}  }}</h1>
    <h1 class="text-[15px] font-bold" *ngIf="flexTicket">{{ 'edit-ticket-type.edit-flex-type' | translate:{title: flexTicket.title}  }}</h1>
    <p *ngIf="error" class="text-[var(--red)] text-center mb-6 break-words mt-6" [innerHTML]="error"></p>

    <div class="h-[calc(100%-120px)] overflow-scroll">
        <ul class="py-5 gap-5 flex flex-col">
            <li *ngFor="let ticketType of ticketTypes" class="flex justify-between items-center">
                <div>
                    <p class="font-bold text-sm">{{ ticketType.name }}</p>
                    <p class="text-sm">{{ ticketType.price_text }}</p>
                </div>
                <button type="button" 
                    *ngIf="ticketType.id == (ticket?.type_id ?? seasonPass?.type_id ?? flexTicket?.type_id)"
                    class="rounded-[10px] w-[200px] px-6 py-3 bg-[var(--red)] text-white outline-none appearance-none border border-[var(--red)]"
                    (click)="hideModal()"    
                >
                {{ 'general.selected' | translate }}</button>
                <button type="button" [ngClass]="{'opacity-30 cursor-progress':  isRequesting == ticketType.id }"
                    (click)="selectTicketType(ticketType.id)" *ngIf="ticketType.id != (ticket?.type_id ?? seasonPass?.type_id ?? flexTicket?.type_id)"
                    class="w-[200px] rounded-[10px] px-6 py-3 bg-white text-[var(--red)] outline-none appearance-none border border-[var(--red)]">
                    <div *ngIf="isRequesting == ticketType.id" class="h-[24px]">
                        <app-spinner />
                    </div>
                    <span *ngIf="isRequesting != ticketType.id">{{ 'general.select' | translate }}</span>
                </button>
            </li>
        </ul>
    </div>
</div>