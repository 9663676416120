<app-ticket-modal class="fixed left-0 top-[56px] w-full h-[calc(100%-76px)] z-10" [url]="pdfUrl" *ngIf="pdfUrl"
    (close)="pdfUrl = undefined" />
<div class="mb-3">
    <div class="justify-between px-0 py-5 sm:flex sm:flex-col-reverse">
        <!-- MATCH -->
        <a [routerLink]="['/fixture', fixtureWrapperObject.fixture.slug] | localize"
            *ngIf="isFixtureTypes(fixtureWrapperObject.fixture, fixtureTypes().MATCH)"
            class="flex justify-between select-none items-center text-white  sm:w-full min-w-[500px] max-w-[50%] sm:max-w-full sm:min-w-0">
            <img class="h-[40px]" [src]="fixtureWrapperObject.fixture.home_team_logo"
                [alt]="fixtureWrapperObject.fixture.teams" />
            <div class="px-2">
                <p class="text-[15px] font-black text-center">{{ fixtureWrapperObject.fixture.teams }}</p>
                <p class="text-sm text-center ">{{ fixtureWrapperObject.fixture.date }} ({{
                    fixtureWrapperObject.fixture.stadium}})</p>
            </div>
            <img class="h-[40px]" [src]="fixtureWrapperObject.fixture.away_team_logo"
                [alt]="fixtureWrapperObject.fixture.teams" />
        </a>
        <!-- OTHER EVENT -->
        <div *ngIf="isFixtureTypes(fixtureWrapperObject.fixture, fixtureTypes().OTHER)"
            class="flex gap-3 items-center text-white  sm:w-full min-w-[500px] max-w-[50%] sm:max-w-full sm:min-w-0">
            <img class="h-[40px]" *ngIf="fixtureWrapperObject.fixture.image" [src]="fixtureWrapperObject.fixture.image"
                [alt]="fixtureWrapperObject.fixture.name" />
            <div>
                <p class="text-[15px] font-black text-left">{{ fixtureWrapperObject.fixture.name}}</p>
                <p class="text-sm text-center ">{{ fixtureWrapperObject.fixture.date }} ({{
                    fixtureWrapperObject.fixture.stadium}})</p>
            </div>
        </div>
    </div>
    <ul class="flex gap-[20px] flex-col">
        <li *ngFor="let ticket of fixtureWrapperObject.tickets; trackBy: trackByTicket">

            <div class="flex flex-col p-5 relative bg-white rounded-[10px]">
                <!-- MOBILE -->
                <div class="hidden sm:block">
                    <div class="flex justify-center mb-3" *ngIf="ticket.flex.is_flex">
                        <p
                            class="hidden sm:inline-block mx-auto bg-[var(--red)] text-white font-bold rounded-[10px] px-3 py-1 text-sm">
                            {{ ticket.flex.title }}</p>
                    </div>

                    <div class="flex justify-between items-center mb-[10px]">
                        <p class="text-sm font-bold">{{ ticket.title }}</p>

                        <!-- INVOICE ACTION-->
                        <ng-container *ngIf="shouldIssueInvoice">
                            <span *ngIf="!isInvoiceIssued && !ticket.is_invoice_issued"
                                class="text-[13px] text-gray-400 block sm:hidden mt-3">
                                {{ 'my-ticket.preview-invoice-desktop' | translate }}
                            </span>
                            <a *ngIf="(isInvoiceIssued || ticket.is_invoice_issued)" target="_blank"
                                [href]="invoiceUrl(ticket)" class="text-[13px] block sm:hidden underline mt-3">{{
                                'my-ticket.preview-invoice' | translate }}</a>
                        </ng-container>
                        <!-- INVOICE ACTION-->

                    </div>
                    <div class="justify-between flex flex-col">
                        <div class="max-w-[50%]">
                            <p class="text-[13px] text-[#a0a0a0]"
                                *ngIf="ticket.personal_details.array?.name || ticket.personal_details.array?.first_name">
                                {{
                                ticket.personal_details.array?.name }} {{ ticket.personal_details.array?.first_name }}
                            </p>
                            <p class="text-[13px] text-[#a0a0a0] -mt-[4px]"
                                *ngIf="ticket.personal_details.array?.birthdate">({{
                                ticket.personal_details.array?.birthdate }})</p>
                        </div>
                        <div *ngIf="false && isCustomer()">
                            <img class="cursor-pointer max-w-[129px]" (click)="getAppleWalletPass(ticket.seat_id)"
                                src="/assets/images/add-to-apple-wallet.png" alt="Apple Wallet-hoz adás">
                        </div>

                        <!-- E TICKET -->
                        <a *ngIf="!ticket.has_pending_transfer" [routerLink]="mobileTicketUrl(ticket)"
                            [queryParams]="queryString"
                            class="mt-[20px] w-full font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center">
                            <span *ngIf="!isDownloading(ticket.seat_id)">{{ 'my-ticket.open-e-ticket' | translate
                                }}</span>
                            <div *ngIf="isDownloading(ticket.seat_id)" class="h-[19px]">
                                <app-spinner />
                            </div>
                        </a>
                        <!-- E TICKET -->

                        <!-- TICKET DOWNLOAD -->
                        <button *ngIf="ticket.is_document_generated && enableMobileTicketDownload" type="button"
                            class="w-full mt-2 font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center"
                            (click)="downloadTicket(ticket.seat_id, true)">
                            <span *ngIf="!isDownloading(ticket.seat_id)">{{ 'my-ticket.download-ticket-2' | translate
                                }}</span>
                            <div *ngIf="isDownloading(ticket.seat_id)" class="h-[19px]">
                                <app-spinner />
                            </div>
                        </button>
                        <!-- TICKET DOWNLOAD -->

                        <!-- CANCEL TRANSFER -->
                        <span *ngIf="ticket.has_pending_transfer">
                            <p class="text-center text-sm text-[#a0a0a0] mt-2"
                                [innerHTML]="'my-season-pass.transfer-in-progress' | translate:{email: ticket.pending_transfers.email}">
                            </p>
                            <div class="mt-4">
                                <app-button [isProcessing]="isRequesting.includes(ticket.uuid!)"
                                    (click)="rekove(ticket)">{{
                                    'my-ticket.revoke' | translate }}</app-button>
                            </div>
                        </span>
                        <!-- CANCEL TRANSFER -->
                    </div>
                </div>
                <!-- MOBILE -->

                <!-- DESKTOP -->
                <div class="flex justify-between sm:hidden">
                    <div>
                        <p class="text-sm font-bold sm:mb-[10px] mb-[2px]">{{ ticket.title }}
                            <span *ngIf="isCashier()">({{ 'my-ticket.barcode' | translate }}:
                                {{ticket.identifier }})</span>
                        </p>
                        <p class="text-[13px] block sm:hidden mb-[2px]">{{ ticket.price }}</p>
                        <p class="text-[13px] block sm:hidden">{{ ticket.type }}</p>

                        <!-- INVOICE ACTION-->
                        <ng-container *ngIf="shouldIssueInvoice">
                            <span *ngIf="!isInvoiceIssued && !ticket.is_invoice_issued"
                                class="text-[13px] text-gray-400 block sm:hidden mt-3">
                                {{ 'my-ticket.preview-invoice-desktop' | translate }}
                            </span>
                            <a *ngIf="(isInvoiceIssued || ticket.is_invoice_issued)" target="_blank"
                                [href]="invoiceUrl(ticket)" class="text-[13px] block sm:hidden underline mt-3">{{
                                'my-ticket.preview-invoice' | translate }}</a>
                        </ng-container>
                        <!-- INVOICE ACTION-->
                    </div>
                    <div class="items-center flex">
                        <p class="text-[13px]">{{ ticket.personal_details.short }}</p>
                    </div>
                    <div class="items-center flex">
                    </div>
                    <div class="flex justify-center items-center gap-3">
                        <!-- APPLE WALLET -->
                        <img class="cursor-pointer max-w-[155px]" *ngIf="false && isCustomer()"
                            (click)="getAppleWalletPass(ticket.seat_id)" src="/assets/images/add-to-apple-wallet.png"
                            alt="Apple Wallet-hoz adás">

                        <!-- TICKET DOWNLOAD -->
                        <button *ngIf="ticket.is_document_generated" type="button"
                            class="w-[200px] font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center"
                            (click)="downloadTicket(ticket.seat_id)">
                            <span *ngIf="!isDownloading(ticket.seat_id)">{{ 'my-ticket.download-ticket' | translate
                                }}</span>
                            <div *ngIf="isDownloading(ticket.seat_id)" class="h-[19px]">
                                <app-spinner />
                            </div>
                        </button>
                        <!-- TICKET DOWNLOAD -->

                        <!-- TICKET GENERATING -->
                        <span *ngIf="!ticket.is_document_generated" class="items-center px-[19px] flex flex-col gap-2">
                            <div>
                                <div class="pdf-loader"></div>
                                <span class="font-bold text-[13px] text-[var(--red)]">{{ 'my-ticket.ticket-in-progress'
                                    |
                                    translate }}</span>
                            </div>
                        </span>
                        <!-- TICKET GENERATING -->

                        <!-- TICKET PRINT -->
                        <button type="button" *ngIf="isCashier()"
                            class="font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center"
                            (click)="printTicket(ticket.seat_id)">
                            <span *ngIf="!isPrinting(ticket.seat_id)">{{ 'my-ticket.print-ticket' | translate }}</span>
                            <div *ngIf="isPrinting(ticket.seat_id)" class="h-[19px]">
                                <app-spinner />
                            </div>
                        </button>
                        <!-- TICKET PRINT -->

                    </div>
                </div>
                <!-- DESKTOP -->
            </div>

            <!-- ADDONS -->
            <div *ngIf="getAddons(ticket).length" class="flex flex-col gap-[10px] mt-[10px]">
                <div *ngFor="let addon of getAddons(ticket); trackBy: trackByAddonId"
                    class="flex justify-between p-5 relative bg-white rounded-[10px]">
                    <div class="sm:w-full">
                        <p class="text-sm font-bold sm:mb-[10px] mb-[2px] sm:text-center">{{ addon.title }}</p>
                        <p class="text-[13px] block sm:hidden">{{ addon.price }}</p>

                        <!-- INVOICE ACTION-->
                        <span *ngIf="!isInvoiceIssued && !ticket.is_invoice_issued"
                            class="text-[13px] text-gray-400 block sm:hidden mt-3">
                            {{ 'my-ticket.preview-invoice-desktop' | translate }}
                        </span>
                        <a *ngIf="(isInvoiceIssued || ticket.is_invoice_issued)" target="_blank"
                            [href]="invoiceUrl(ticket)" class="text-[13px] block sm:hidden underline mt-3">
                            {{ 'my-ticket.preview-invoice' | translate }}
                        </a>
                        <!-- INVOICE ACTION-->

                        <!-- E TICKET -->
                        <a *ngIf="!ticket.has_pending_transfer" [routerLink]="mobileAddonTicketUrl(ticket, addon)"
                            [queryParams]="queryString"
                            class="hidden mt-[20px] w-full font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] sm:flex gap-[5px] justify-center items-center">
                            <span *ngIf="!isDownloading(ticket.seat_id)">{{ 'my-ticket.open-e-ticket' | translate
                                }}</span>
                            <div *ngIf="isDownloading(ticket.seat_id)" class="h-[19px]">
                                <app-spinner />
                            </div>
                        </a>
                        <!-- E TICKET -->
                    </div>

                    <div>
                        <!-- ADDON DOWNLOAD -->
                        <button *ngIf="addon.is_document_generated"
                            type="button"
                            class="sm:hidden w-[200px] font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center"
                            (click)="downloadAddon(addon.uuid, ticket.seat_id)">
                            <span *ngIf="!isAddonDownloading(addon.uuid)">{{ 'my-ticket.addon.open-e-ticket' | translate
                                }}</span>
                            <div *ngIf="isAddonDownloading(addon.uuid)" class="h-[19px]">
                                <app-spinner />
                            </div>
                        </button>
                        <!-- ADDON DOWNLOAD -->

                        <!-- ADDON GENERATING -->
                        <span *ngIf="!addon.is_document_generated"
                            class="sm:hidden items-center px-[19px] flex flex-col gap-2">
                            <div>
                                <div class="pdf-loader"></div>
                                <span class="font-bold text-[13px] text-[var(--red)]">{{
                                    'my-ticket.addon.e-ticket-in-progress' | translate}}</span>
                            </div>
                        </span>
                        <!-- ADDON GENERATING -->

                    </div>
                </div>
            </div>
            <!-- ADDONS -->
        </li>
        <li *ngIf="showBackToMatch">
            <a [routerLink]="['/fixture', fixtureWrapperObject.fixture.slug] | localize" class="text-white underline">{{
                'my-ticket.back-to-fixture' | translate }}</a>
        </li>
    </ul>
</div>