import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';
import { ModalService } from 'src/app/services/Modal/modal.service';

@Component({
  selector: 'app-cart-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public cart: Cart = {} as Cart
  public calculateTimeInterval: any
  public remainingSeconds: number = -1
  public remainingTime?: String
  public worker?: Worker

  @Input() cartCountdownCounter: boolean = true

  constructor(
    private _store: Store,
    private _alertService: AlertService,
    private _cartService: CartService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this._store.subscribe((state: any) => {
      this.cart = state.cart

      if (!Object.keys(this.cart).length) {
        this._destroy()
      } else if (this.remainingSeconds == -1 && this.cart.checkout_until) {
        this.remainingSeconds = this.cart.checkout_until
        this._startCountdown();
      }
    });
  }

  private _startCountdown() {
    if (this.cartCountdownCounter) {
      this.calculateRemainingSeconds()

      /* if worker is available in the browser */
      if (typeof Worker !== 'undefined') {
        this.worker = new Worker("./assets/worker.js");

        this.worker.postMessage(1000)

        this.worker.onmessage = () => this.runRemainingTimeInterval()
      }
      /* if browser does not support worker */
      else {
        this.calculateTimeInterval = window.setInterval(() => {
          this.runRemainingTimeInterval()
        }, 1000)
      }
    }
  }

  private runRemainingTimeInterval() {
    if (this.remainingSeconds) {
      this.remainingSeconds -= 1
    }

    this.calculateRemainingSeconds()
  }

  ngOnDestroy(): void {
    this._destroy()
  }

  private _destroy() {
    this.remainingTime = undefined

    if (this.calculateTimeInterval) {
      window.clearInterval(this.calculateTimeInterval);
    }
    if(this.worker) {
      this.worker.terminate()
      this.worker = undefined
    }
  }

  /* calculates the number of items in the cart */
  protected calculateNumberOfItemsInCart() {
    /* if the cart is empty */
    if (!this.cart.items) return 0

    return this.getNumberOfTicketsInCart() + this.getNumberOfSeasonPassesInCart() + this.getNumberOfFlexTicketsInCart();
  }

  get isCartEmpty(): boolean {
    return this.getNumberOfTicketsInCart() || this.getNumberOfSeasonPassesInCart() || this.getNumberOfFlexTicketsInCart() ? false : true;
  }

  /* calculates the number of tickets in the cart */
  private getNumberOfTicketsInCart() {
    /* if tickets are empty */
    if (!this.cart || !this.cart.items || !this.cart.items.events) return 0;

    /* calculate number of tickets tickets */
    const tickets = this.cart.items.events
    let numberOfTickets = 0;
    Object.keys(tickets).map(fixtureId => {
      numberOfTickets += Object.keys(tickets[fixtureId].tickets).length
    })

    return numberOfTickets;
  }

  private getNumberOfFlexTicketsInCart() {
     /* if flex tickets are empty */
     if (!this.cart || !this.cart.items || !this.cart.items.flex_tickets) return 0;

     /* calculate number of tickets tickets */
    const flexTickets = this.cart.items.flex_tickets
    
    let numberOfTickets = 0;

    Object.keys(flexTickets).map(flexRuleUuid => {
      numberOfTickets += Object.keys(flexTickets[flexRuleUuid].flex_tickets).length
    })

    return numberOfTickets;
  }

  private getNumberOfSeasonPassesInCart() {
    /* if tickets are empty */
    if (!this.cart || !this.cart.items || !this.cart.items.season_passes) return 0;

    /* calculate number of tickets tickets */
    const seasonPasses = this.cart.items.season_passes
    let numberOfTickets = 0;
    Object.keys(seasonPasses).map(seasonPassSlug => {
      numberOfTickets += Object.keys(seasonPasses[seasonPassSlug].season_passes).length
    })

    return numberOfTickets;
  }

  private calculateRemainingSeconds(): void 
  {
    let difference = this.remainingSeconds

    if (difference <= 0 || isNaN(difference)) {
      this.remainingTime = undefined
        window.clearInterval(this.calculateTimeInterval);
      if (difference <= 0) {
        const cartExpiredText = this._translateService.instant('cart.expired');
        this._alertService.error(cartExpiredText)
        this._cartService.clearCart()
        this._modalService.hideModal()
        this.remainingSeconds = -1
      }
      return;
    }

    const secondsInAMinute = 60

    let remaining = {
      minutes: 0,
      seconds: 0
    }

    difference -= (remaining.minutes = Math.floor(difference / secondsInAMinute)) * secondsInAMinute
    remaining.seconds = difference

    this.remainingTime = String(remaining.minutes).padStart(2, "0") + ':' + String(remaining.seconds).padStart(2, "0")
  }
}