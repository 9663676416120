import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { Redirect } from 'src/app/enums/cart/checkout/redirect';
import { UserType } from 'src/app/enums/user/userType';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { OrderResult } from 'src/app/interfaces/State/Order/OrderResult';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';
import { HelperService } from 'src/app/services/Misc/Helper/helper.service';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { OrderService } from 'src/app/services/Order/order.service';

@Component({
  selector: 'app-order-result',
  templateUrl: './order-result.component.html',
  styleUrls: ['./order-result.component.scss']
})
export class OrderResultComponent implements OnInit {
  private destroy$ = new Subject<void>();
  public orderId: string
  public remainingTicketCountForPrint$ = new Subject<number>();
  public orderResult: OrderResult = {} as OrderResult
  public isLoadingInitially: boolean = false
  public errorMsg: string = ""
  public auth: Auth = {} as Auth
  public interval: any
  public worker?: Worker
  public isDownloadingMergedTickets: boolean = false
  public isSendingSuccessfulEmail: boolean = false
  public mapOnly: boolean = false

  /* retry until */
  public calculateTimeInterval: any
  public remainingSeconds: number = -1
  public remainingTime?: String
  public worker2?: Worker
  public isRetrying: boolean = false
  public isRedirectingToPay: boolean = false

  constructor(
    private _store: Store,
    private _route: ActivatedRoute,
    private _alertService: AlertService,
    private _router: Router,
    private _orderService: OrderService,
    private _cartService: CartService,
    private _translateService: TranslateService,
    private _modalService: ModalService,
    private _helperService: HelperService,
  ) {
    this.orderId = this._route.snapshot.paramMap.get('id')!
    this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
    this._stopInterval()
  }

  ngOnInit(): void {
    this._cartService.getCart()
    this.init()
    this._router.events.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.init()
      }
    });

    this._store.pipe(takeUntil(this.destroy$)).subscribe((state: any) => {
      if (Object.keys(state.order.orderResult).length) {
        this.orderResult = state.order.orderResult

        /* refresh */
        if (!this.orderResult.need_to_refresh) {
          this._stopInterval()
        } else if (!this.interval && !this.worker) {
          this._startInterval()
        }

        /* retry countdown */
        if(this.remainingSeconds == -1 && this.orderResult.can_be_retried && this.orderResult.can_be_retried_until) {
          this.remainingSeconds = this.orderResult.can_be_retried_until
          this._startCountdown()
        }
      }
    })

    this._store.pipe(takeUntil(this.destroy$)).subscribe((state: any) => {
      this.auth = state.auth
    })
  }

  async init() {
    this.isLoadingInitially = true
    const res = await this._orderService.getOrder(this.orderId)
    this.isLoadingInitially = false
    if (res != true) {
      const errorHappenedText = this._translateService.instant('general.error-happened');

      this._alertService.error(errorHappenedText, res.error)
      this.errorMsg = res.error
    }
  }

  private _stopInterval() {    
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.interval = undefined

    if (this.worker) {
      this.worker.terminate()
      this.worker = undefined
    }
  }

  private _startInterval() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker("./assets/worker.js");

      this.worker.postMessage(5000)

      this.worker.onmessage = () => this._orderService.getOrder(this.orderId)
    }
    /* if browser does not support worker */
    else {
      setInterval(() => {
        this._orderService.getOrder(this.orderId)
      }, 8000)
    }
  }

  public isCashier(): boolean {
    return this.auth.user.type == UserType.CASHIER
  }

  public isCustomer(): boolean {
    return this.auth.user.type == UserType.CUSTOMER
  }

  public isPrinting(): boolean {
    return this.remainingTicketCountForPrint$.closed;
  }

  public async printAllTickets() {
    this.remainingTicketCountForPrint$.next(this.orderResult.tickets.length);
  }

  public async sendSuccessfulEmail() {
    if (this.isSendingSuccessfulEmail) return;

    this.isSendingSuccessfulEmail = true

    try {
      const res = await this._orderService.sendSuccesfulEmail(this.orderId)

      if (res.success) {
        const emailSendingFailed = this._translateService.instant('order-result.email-sending-failed');

          this._alertService.success(
            emailSendingFailed,
            res.error ?? '',
          )
      } else {
        this._alertService.error(
          undefined,
          res.error ?? '',
        )
      }
    } catch (e: any) {
      const errorHappenedText = this._translateService.instant('general.error-happened');

      this._alertService.error(
        errorHappenedText,
        e.error.error ?? '',
      )
    } finally {
      this.isSendingSuccessfulEmail = false
    }
  }

  public async downloadMergedTickets() {
    if (this.isDownloadingMergedTickets) return;

    this.isDownloadingMergedTickets = true

    try {
      const res = await this._orderService.downloadMergedTickets(this.orderId)

      if (res.success) {
        window.open(res.data.url, '_blank');
      } else {
        this._alertService.error(
          undefined,
          res.error ?? '',
        )
      }
    } catch (e: any) {
      const errorHappenedText = this._translateService.instant('general.error-happened');

      this._alertService.error(
        errorHappenedText,
        e.error.error ?? '',
      )
    } finally {
      this.isDownloadingMergedTickets = false
    }
  }

  private _stopCountdown() 
  {
    this.remainingTime = undefined

    if (this.calculateTimeInterval) {
      window.clearInterval(this.calculateTimeInterval);
    }
    if(this.worker2) {
      this.worker2.terminate()
      this.worker2 = undefined
    }
  }

  private _startCountdown() {
    this.calculateRemainingSeconds()

    /* if worker is available in the browser */
    if (typeof Worker !== 'undefined') {
      this.worker2 = new Worker("./assets/worker.js");

      this.worker2.postMessage(1000)

      this.worker2.onmessage = () => this.runRemainingTimeInterval()
    }
    /* if browser does not support worker */
    else {
      this.calculateTimeInterval = window.setInterval(() => {
        this.runRemainingTimeInterval()
      }, 1000)
    }
  }

  private runRemainingTimeInterval() {
    if (this.remainingSeconds) {
      this.remainingSeconds -= 1
    }

    this.calculateRemainingSeconds()
  }

  private calculateRemainingSeconds(): void {

    let difference = this.remainingSeconds

    if (difference <= 0 || isNaN(difference)) {
      this.remainingTime = undefined
      window.clearInterval(this.calculateTimeInterval);

      if (difference <= 0) {
        const paymentRetryExpired = this._translateService.instant('order.payment-retry-expired');
        this._alertService.error(paymentRetryExpired)
        this._modalService.hideModal()
        this.remainingSeconds = -1
        this._stopCountdown()
      }
      return;
    }

    const secondsInAMinute = 60

    let remaining = {
      minutes: 0,
      seconds: 0
    }

    difference -= (remaining.minutes = Math.floor(difference / secondsInAMinute)) * secondsInAMinute
    remaining.seconds = difference

    this.remainingTime = String(remaining.minutes).padStart(2, "0") + ':' + String(remaining.seconds).padStart(2, "0")
  }

  public async retry()
  {
    this.isRetrying = true
    
    const res = await this._orderService.retry(this.orderResult.id!, {onlyMap : this.mapOnly})

    this.isRetrying = false

    if(res.status) {
      const errorHappenedText = this._translateService.instant('general.error-happened');

      this._alertService.error(errorHappenedText, res.error)
      return ;
    }

    this.isRedirectingToPay = true

    /* on site navigation */
    if(res.data.redirect == Redirect.ONSITE) {
      const url = this._helperService.translateRoute(`order/${res.data.order_id}`)

      this._router.navigate([url]);
      return ;
    } 

    /* off site navigation */
    if(res.data.redirect == Redirect.OFFSITE) {
      window.location.href = res.data.url
    }
  }

  get actionButtonText() {

    if(this.isRedirectingToPay) {
      const redirecting = this._translateService.instant('order.redirecting');
      return redirecting
    }

    const errorHappenedText = this._translateService.instant('order.payment-retry');

    return `${errorHappenedText} (${this.remainingTime})`
  }

  public hasSeasonPasses()
  {
    return this.orderResult.season_passes && Object.keys(this.orderResult.season_passes).length ? true : false
  }

  public hasFlexTickets()
  {
    return this.orderResult.flex_tickets && Object.keys(this.orderResult.flex_tickets).length ? true : false
  }
}
