import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-ticket-modal',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LocalizeRouterModule,
  ],
  templateUrl: './ticket-modal.component.html',
  styleUrls: ['./ticket-modal.component.scss']
})
export class TicketModalComponent {
  @Input() url?: string
  @Output() close = new EventEmitter<void>();
  
  public errorMsg: string = ""
  public myUrl: SafeResourceUrl | null = null;
  

  constructor(
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnChanges(): void {
    if (this.url) {
      this.myUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    } else {
      this.myUrl = null;
    }
  }

  public closeFn() {
    this.close.emit();
  }
}
