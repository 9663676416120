<div class=" mb-3">
    <div class="justify-between px-0 py-5 sm:flex sm:flex-col-reverse">
        <a [routerLink]="['/season-pass', seasonPassWrapperObject.details.slug] | localize"
            class="flex justify-between items-center text-white  sm:w-full min-w-[500px] max-w-[50%] sm:max-w-full sm:min-w-0">
            <div class="px-2">
                <p class="text-[15px] font-black text-left">{{ seasonPassWrapperObject.details.name }}</p>
                <p class="text-sm text-left ">{{ seasonPassWrapperObject.details.subtitle }}</p>
            </div>
        </a>
    </div>
    <ul class="flex gap-[20px] flex-col">

        <li class="flex flex-col p-5 relative bg-white rounded-[10px]"
            *ngFor="let seasonPass of seasonPassWrapperObject.season_passes; trackBy: trackBySeasonPass">
            <!-- MOBILE -->
            <div class="hidden sm:block">
                <div class="flex justify-between items-center mb-[10px]">
                    <p class="text-sm font-bold">{{ seasonPass.title }}</p>
                    <ng-container *ngIf="shouldIssueInvoice">
                        <!-- INVOICE ACTION -->
                        <span *ngIf="!isInvoiceIssued && !seasonPass.is_invoice_issued && !mapOnly"
                            class="text-[13px] text-[#a0a0a0] text-right">{{ 'my-season-pass.invoice-issue-in-progress'
                            | translate }}</span>
                        <a target="_blank" *ngIf="(isInvoiceIssued || seasonPass.is_invoice_issued) && !mapOnly"
                            [href]="invoiceUrl(seasonPass)" class="text-[13px] text-[#a0a0a0] underline">{{
                            'my-season-pass.preview-invoice' | translate }}</a>
                        <!-- INVOICE ACTION -->
                    </ng-container>
                </div>
                <div class="justify-between flex">
                    <div class="max-w-[50%]">
                        <p class="text-[13px] text-[#a0a0a0]"
                            *ngIf="seasonPass.personal_details?.array?.name || seasonPass.personal_details?.array?.first_name">
                            {{ seasonPass.personal_details?.array?.name }} {{
                            seasonPass.personal_details?.array?.first_name }}</p>
                        <p class="text-[13px] text-[#a0a0a0] -mt-[4px]"
                            *ngIf="seasonPass.personal_details?.array?.birthdate">({{
                            seasonPass.personal_details?.array?.birthdate }})</p>
                    </div>
                    <div *ngIf="false">
                        <img class="cursor-pointer max-w-[129px]" (click)="getAppleWalletPass(seasonPass.seat_id)"
                            src="/assets/images/add-to-apple-wallet.png" alt="Apple Wallet-hoz adás">
                    </div>
                </div>

                <!-- MOBILE TICKET -->
                <a *ngIf="!seasonPass.has_pending_transfer" [routerLink]="mobileTicketUrl(seasonPass)"
                    [queryParams]="queryString"
                    class="mt-[20px] w-full font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center">
                    <span>{{ 'my-season-pass.open-e-ticket' | translate}}</span>
                </a>

                <!-- CANCEL TRANSFER -->
                <span *ngIf="seasonPass.has_pending_transfer">
                    <p class="text-center text-sm text-[#a0a0a0] mt-2"
                        [innerHTML]="'my-season-pass.transfer-in-progress' | translate:{email: seasonPass.pending_transfers.email}">
                    </p>
                    <div class="mt-4">
                        <app-button [isProcessing]="isRequesting.includes(seasonPass.uuid!)"
                            (click)="revoke(seasonPass)">{{ 'my-season-pass.my-season-pass' | translate }}</app-button>
                    </div>
                </span>
                <!-- CANCEL TRANSFER -->
            </div>
            <!-- DESKTOP -->
            <div class="flex justify-between sm:hidden">
                <div>
                    <p class="text-sm font-bold sm:mb-[10px] mb-[2px]">{{ seasonPass.title }} <span
                            *ngIf="isCashier()">({{ 'my-season-pass.barcode' | translate }}:
                            {{seasonPass.identifier }})</span></p>
                    <p class="text-[13px] block sm:hidden mb-[2px]">{{ seasonPass.price }}</p>
                    <p class="text-[13px] block sm:hidden">{{ seasonPass.type }}</p>

                    <!-- INVOICE ACTION-->
                    <ng-container *ngIf="shouldIssueInvoice">
                        <span *ngIf="!isInvoiceIssued && !seasonPass.is_invoice_issued && !mapOnly"
                            class="text-[13px] text-gray-400 block sm:hidden mt-3">
                            {{ 'my-season-pass.preview-invoice-desktop' | translate }}
                        </span>
                        <a *ngIf="(isInvoiceIssued || seasonPass.is_invoice_issued) && !mapOnly" target="_blank"
                            [href]="invoiceUrl(seasonPass)" class="text-[13px] block sm:hidden underline mt-3">{{
                            'my-season-pass.preview-invoice' | translate }}</a>
                    </ng-container>
                    <!-- INVOICE ACTION-->


                </div>
                <div class="items-center flex">
                    <p class="text-[13px]">{{ seasonPass.personal_details?.short }}</p>
                </div>
                <div class="items-center flex">
                </div>
                <div class="flex justify-center items-center gap-3">
                    <!-- APPLE WALLET PASS -->
                    <img *ngIf="false && isCustomer()" class="cursor-pointer max-w-[155px]"
                        (click)="getAppleWalletPass(seasonPass.seat_id)" src="/assets/images/add-to-apple-wallet.png"
                        alt="Apple Wallet-hoz adás">

                    <!-- DOWNLOAD -->
                    <button *ngIf="seasonPass.is_document_generated" type="button"
                        class="font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-between items-center"
                        (click)="downloadSeasonPass(seasonPass.seat_id)">{{ isDownloading(seasonPass.seat_id) ?
                        ('my-season-pass.downloading-season-pass-in-progress' | translate) :
                        ('my-season-pass.download-season-pass' | translate) }}</button>

                    <!-- TICKET GENERATING -->
                    <span *ngIf="!seasonPass.is_document_generated" class="px-[19px]">
                        <div>
                            <div class="pdf-loader"></div>
                            <span class="font-bold text-[13px] text-[var(--red)]">{{
                                'my-season-pass.season-pass-in-progress' | translate }}</span>
                        </div>
                    </span>


                    <!-- PRINT -->
                    <button type="button" *ngIf="isCashier()"
                        class="font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-between items-center"
                        (click)="printSeasonPass(seasonPass.seat_id)">{{ isPrinting(seasonPass.seat_id) ?
                        ('my-season-pass.printing-season-pass-in-progress' | translate) :
                        ('my-season-pass.print-season-pass' | translate) }}</button>
                </div>
            </div>
        </li>
        <li *ngIf="showBackToMatch">
            <a [routerLink]="['/season-pass', seasonPassWrapperObject.details.slug] | localize"
                class="text-white underline">{{ 'my-season-pass.back-to-season-pass' | translate }}</a>
        </li>
    </ul>
</div>