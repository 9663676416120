import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { Ticket } from 'src/app/interfaces/State/CartItem/ticket';
import { CartFlexTicket } from 'src/app/interfaces/State/Flex/CartFlexTicket';
import { CartSeasonPass } from 'src/app/interfaces/State/SeasonPass/cartSeasonPass';
import { SectorDetail } from 'src/app/interfaces/State/Stadium/sector-detail';
import { StadiumWithSectorsAndSeats } from 'src/app/interfaces/State/Stadium/stadium-with-sectors-and-seats';
import { CartTicket } from 'src/app/interfaces/State/Ticket/cartTicket';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';

@Component({
  selector: 'app-stadium-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnChanges{
  @Input() cart: Cart = {} as Cart
  @Input() currentFixtureId: string = ""  
  @Input() currentSeasonPassSlug: string = ""  
  @Input() currentFlexUuid: string = ""  
  @Input() stadiumState: StadiumWithSectorsAndSeats = {} as StadiumWithSectorsAndSeats

  public showEditTicketTypeForm: boolean = false
  public showEditSeasonPassTypeForm: boolean = false
  public showEditFlexTicketTypeForm: boolean  = false
  public myTickets: any 
  public mySeasonPasses: any
  public myFlexTickets: any
  public ticketTypes: {[key:number]: SectorDetail} = {}
  public ticketToEdit: CartTicket = {} as CartTicket
  public seasonPassToEdit: CartSeasonPass = {} as CartSeasonPass
  public flexTicketToEdit: CartFlexTicket = {} as CartFlexTicket
  public isRequesting: number | boolean = false
 
  @Output() editingTicketTypeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() editingSeasonPassTypeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() editingFlexTicketTypeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _alertService: AlertService,
    private _cartService: CartService,
    private _cdr: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
  ) {

  }

  ngOnInit() {
    this.tickets()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tickets()
  }

  public tickets() {
    const tickets = this.cart?.items?.events ?? {}
    const seasonPasses = this.cart?.items?.season_passes ?? {}
    const flexes = this.cart?.items?.flex_tickets ?? {}

    /* if there is no tickets and season passes in the cart */
    if(!Object.keys(tickets).length && !Object.keys(seasonPasses).length && !Object.keys(flexes).length) {
      this.myTickets = []
      this.mySeasonPasses = []
      this.myFlexTickets = []
      return ;
    }

    let otherFixturesTickets: Array<CartTicket> = []
    let currentFixturesTickets: Array<CartTicket> = []
    let currentSeasonPasses: Array<CartSeasonPass> = []
    let otherSeasonPasses: Array<CartSeasonPass> = []
    let currentFlexTickets: Array<CartFlexTicket> = []

    const eventIds = Object.keys(tickets)
    const seasonPassIds = Object.keys(seasonPasses)
    const flexIds = Object.keys(flexes)

    /* FLEX */
    flexIds.map((flexId) => {
      const flex = flexes[flexId]
      
      const flexTicketsList = { ...flex.flex_tickets }

      const flexTicketsListIds = Object.keys(flexTicketsList)

      flexTicketsListIds.map((id: any) => {
        const ticket = flexTicketsList[id]
        const object = {
            flex: flex.details,
            title: ticket.title,
            type: ticket.type,
            typeId: ticket.type_id,
            id: ticket.seat_id, 
            priceText:  ticket.price,
            sectorId:ticket.sector_id,
            types: ticket.types,
            fixture_ids: ticket.fixture_ids,
            uuid: ticket.uuid,
            seat_id : ticket.seat_id
        } as CartFlexTicket

        currentFlexTickets.push(object)
      })
    })
 
    /* SEASON PASSES */
    seasonPassIds.map((seasonPassId) => {
      const seasonPass = seasonPasses[seasonPassId]

      const seasonPassesList = { ...seasonPass.season_passes }

      const seasonPassIdsList = Object.keys(seasonPassesList)

      seasonPassIdsList.map((id) => {
        const ticket = seasonPassesList[id]
        if (seasonPass.details.slug == this.currentSeasonPassSlug) {
          const object = {
            seasonPass: seasonPass.details.name,
            seasonPassSlug: seasonPass.details.slug,
            title: ticket.title,
            type: ticket.type,
            typeId: ticket.type_id,
            id: ticket.seat_id,
            sectorId: ticket.sector_id,
            priceText: ticket.price,
            types: ticket.types,
          } as CartSeasonPass

          currentSeasonPasses.push(object)

          //this.updateTicketUnderEdit(ticket.seat_id, object)

        } else {
          const object = {
            seasonPass: seasonPass.details.name,
            seasonPassSlug: seasonPass.details.slug,
            title: ticket.title,
            type: ticket.type,
            id: ticket.seat_id,
            priceText: ticket.price,
            sectorId: 0,
            typeId: ticket.type_id,
            types: ticket.types,
          } as CartSeasonPass
          
          otherSeasonPasses.push(object)

          //this.updateTicketUnderEdit(ticket.seat_id, object)
        }
      })
    })

    /* TICKETS */
    eventIds.map((eventId) => {
      const event = tickets[eventId]

      const eventTickets = { ...event.tickets }

      const ticketIds = Object.keys(eventTickets)

      ticketIds.map((ticketId) => {
        const ticket = eventTickets[ticketId]
        
        if (event.fixture.id == this.currentFixtureId) {
          const object = {
            event: event.fixture.title,
            eventId: event.fixture.id,
            title: ticket.title,
            type: ticket.type,
            typeId: ticket.type_id,
            id: ticket.seat_id,
            sectorId: ticket.sector_id,
            priceText: ticket.price,
            types: ticket.types,
          }

          currentFixturesTickets.push(object)

          this.updateTicketUnderEdit(ticket.seat_id, object)

        } else {
          const object = {
            event: event.fixture.title,
            eventId: event.fixture.id,
            title: ticket.title,
            type: ticket.type,
            id: ticket.seat_id,
            priceText: ticket.price,
            sectorId: 0,
            typeId: ticket.type_id,
            types: ticket.types,
          }
          
          otherFixturesTickets.push(object)

          this.updateTicketUnderEdit(ticket.seat_id, object)
        }

      })
    })
    
    this.myTickets = currentFixturesTickets.concat(otherFixturesTickets)
    this.mySeasonPasses = currentSeasonPasses.concat(otherSeasonPasses)
    this.myFlexTickets = currentFlexTickets
  }

  /* SHOW TICKET EDIT FORM */
  public showTicketEditForm(data: {ticket: CartTicket}) 
  {
    this.ticketToEdit = data.ticket

    this.ticketTypes = this.ticketToEdit.types ?? this.stadiumState.sectors[data.ticket.sectorId as any].dt

    if(!this.ticketToEdit) {
      const errorHappenedText = this._translateService.instant('general.error-happened');
      const ticketIsNotValid = this._translateService.instant('cart.ticket-is-not-valid');

      this._alertService.error(errorHappenedText, ticketIsNotValid)
      return;
    }

    if(!this.ticketTypes) {
      const errorHappenedText = this._translateService.instant('general.error-happened');
      const ticketNotEditableText = this._translateService.instant('cart.ticket-not-editable');
      
      this._alertService.error(errorHappenedText, ticketNotEditableText)
      return ;
    }

    this.showEditTicketTypeForm = true
    this.editingTicketTypeEventFn(true)
  }

  /* SHOW SEASON PASS EDIT FORM */
  public showSeasonPassEditForm(data: {seasonPass: CartSeasonPass}) 
  {
    this.seasonPassToEdit = data.seasonPass
    
    this.ticketTypes = this.seasonPassToEdit.types ?? this.stadiumState.sectors[data.seasonPass.sectorId as any].dt

    if(!this.seasonPassToEdit) {
      const errorHappenedText = this._translateService.instant('general.error-happened');
      const seasonPassNotValidText = this._translateService.instant('general.season-pass-not-valid');
      
      this._alertService.error(errorHappenedText, seasonPassNotValidText)
      return;
    }
    if(!this.ticketTypes) {
      const errorHappenedText = this._translateService.instant('general.error-happened');
      const seasonPassEditableText = this._translateService.instant('cart.season-pass-not-editable');

      this._alertService.error(errorHappenedText, seasonPassEditableText)
      return ;
    }
    this.showEditSeasonPassTypeForm = true
    this.editingSeasonPassTypeEventFn(true)
  }

  /* SHOW FLEX TICKET EDIT FORM */
  public showFlexTicketEditForm(data: {flexTicket: CartFlexTicket}) 
  {    
    this.flexTicketToEdit = data.flexTicket
    
    this.ticketTypes = this.flexTicketToEdit.types ?? this.stadiumState.sectors[data.flexTicket.sectorId as any].dt

    if(!this.flexTicketToEdit) {
      this._alertService.error('Hiba történt!', 'A flex jegy nem megfelelő!')
      return;
    }
    if(!this.ticketTypes) {
      this._alertService.error('Hiba történt!', 'A flex jegy típusa nem módosítható!')
      return ;
    }

    this.showEditFlexTicketTypeForm = true

    this.editingFlexTicketTypeEventFn(true)
  }


  public hideEditTicketTypeForm() {
    this.showEditTicketTypeForm = false
    this.ticketToEdit = {} as CartTicket
    this.ticketTypes = [] as Array<SectorDetail>
    this._cdr.detectChanges()
    this.editingTicketTypeEventFn(false)
  }

  public hideEditSeasonPassTypeForm() {
    this.showEditSeasonPassTypeForm = false
    this.seasonPassToEdit = {} as CartSeasonPass
    this.ticketTypes = [] as Array<SectorDetail>
    this._cdr.detectChanges()
    this.editingSeasonPassTypeEventFn(false)
  }

  public hideEditFlexTicketTypeForm() {
    this.showEditFlexTicketTypeForm = false
    this.flexTicketToEdit = {} as CartFlexTicket
    this.ticketTypes = [] as Array<SectorDetail>
    this._cdr.detectChanges()
    this.editingFlexTicketTypeEventFn(false)
  }

  /* SELECT / EDIT TICKET TYPE */
  public async selectTicketType(ticketTypeId: number) {
    if(this.isRequesting) return ;

    this.isRequesting = ticketTypeId
    const res = await this._cartService.updateCartTicketType(this.ticketToEdit.id, this.currentFixtureId, ticketTypeId)

    this.isRequesting = false

    if(res.error) {
      const errorHappenedText = this._translateService.instant('general.error-happened');
      const editingFailedText = this._translateService.instant('general.editing-failed');

      this._alertService.error(errorHappenedText, res.error.error ?? res.error ?? editingFailedText)
    }

    setTimeout(() => {
      this.hideEditTicketTypeForm()
    }, 500)
    
  }

  /* SELECT / EDIT SEASON PASS TYPE */
  public async selectSeasonPassType(ticketTypeId: number) {
    if(this.isRequesting) return ;

    this.isRequesting = ticketTypeId
    const res = await this._cartService.updateSeasonPassTicketType(this.seasonPassToEdit.id, this.currentSeasonPassSlug, ticketTypeId)

    this.isRequesting = false
    if(res.error) {
      const errorHappenedText = this._translateService.instant('general.error-happened');
      const editingFailedText = this._translateService.instant('general.editing-failed');

      this._alertService.error(errorHappenedText, res.error.error ?? res.error ?? editingFailedText)
    }

    setTimeout(() => {
      this.hideEditSeasonPassTypeForm()
    }, 500)
    
  }
  
  /* SELECT / EDIT FLEX TICKET TYPE */
  public async selectFlexTicketType(ticketTypeId: number) {
    if(this.isRequesting) return ;

    this.isRequesting = ticketTypeId
    const res = await this._cartService.updateFlexTicketType(this.flexTicketToEdit.uuid, this.currentFlexUuid, ticketTypeId)

    this.isRequesting = false

    if(res.error) {
      this._alertService.error('Hiba történt!', res.error.error ?? res.error ?? 'A módosítás sikertelen!')
    }

    setTimeout(() => {
      this.hideEditFlexTicketTypeForm()
    }, 500)
    
  }

  private updateTicketUnderEdit(seatId: number, ticket: CartTicket) {
    if(!Object.keys(this.ticketToEdit).length || this.ticketToEdit.id != seatId) return ;

    this.ticketToEdit = ticket
  }

  public editingTicketTypeEventFn(event: boolean)
  {
    this.editingTicketTypeEvent.emit(event);
  }

  public editingSeasonPassTypeEventFn(event: boolean)
  {
    this.editingSeasonPassTypeEvent.emit(event);
  }

  public editingFlexTicketTypeEventFn(event: boolean)
  {
    this.editingFlexTicketTypeEvent.emit(event);
  }

  get queryString() {
    return this._route.snapshot.queryParams
  }
  
}
