<div class="container">
    <a *ngIf="mapOnly" 
    [routerLink]="['/my-tickets'] | localize" 
    [queryParams]="queryString" 
    class="cursor-pointer mt-5 flex gap-2 items-center text-white font-bold"><app-back-icon/> {{ 'general.back' | translate }}</a>

    <div class="rounded-[10px] px-0 max-w-full" [ngClass]="{'mb-10' : mapOnly, 'my-10' : !mapOnly}">
        <div class="bg-white rounded-[10px] p-6 mt-6">
            <p class="text-center" *ngIf="!myUrl">{{ 'order-invoice.invoice-opening-in-progress' | translate }}</p>

            <iframe id="invoice" *ngIf="myUrl" [src]="myUrl"></iframe>

        </div>

        <ng-container *ngIf="errorMsg">
            <div class="bg-white rounded-[10px] p-6 mt-6">
                <p class="text-center" [innerHTML]="errorMsg"></p>
            </div>
        </ng-container>
    </div>
</div>