import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { from, Observable, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { HelperService } from 'src/app/services/Misc/Helper/helper.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  state?: any

  constructor(
    private _store: Store,
    private _helperService: HelperService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this._helperService.getCurrentLanguage()).pipe(
      switchMap((currentLanguage) => {
        // Modify request if needed
        const modifiedRequest = request.clone({
          setHeaders: { 'Accept-Language': currentLanguage }
        });
  
        return next.handle(modifiedRequest);
      })
    );
  }
}
