import { createAction, props } from "@ngrx/store";
import { Index } from "../interfaces/State/MyTickets/index";
import { SingleTicket } from "../interfaces/State/MyTickets/singleTicket";
import { SingleSeasonPass } from "../interfaces/State/MySeasonPasses/singleSeasonPass";
import { Ticket } from "../interfaces/State/MyTickets/ticket"
import { SingleFixtureAddon } from "../interfaces/State/FixtureAddon/singleFixtureAddon";

export const setUpcomingMyTickets = createAction('setUpcomingMyTickets', props<{tickets: Array<Index>}>())
export const clearUpcomingMyTickets = createAction('clearUpcomingMyTickets')
export const setPastMyTickets = createAction('setOpenesetPastMyTicketsdFixture', props<{tickets: Array<Index>}>())
export const setETicket = createAction('setETicket', props<{ticket: SingleTicket}>())
export const clearETicket = createAction('clearETicket')
export const setESeasonPass = createAction('setESeasonPass', props<{seasonPass: SingleSeasonPass}>())
export const clearESeasonPass = createAction('clearESeasonPass')
export const updateUpcomingMyTicket = createAction('updateUpcomingMyTicket', props<{ticket:Ticket, fixtureSlug: string}>())