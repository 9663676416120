import { createAction, props } from "@ngrx/store";
import { SoldSeats } from "../interfaces/State/Seat/sold-seats";
import { SeatDetails } from "../interfaces/State/Seat/seat-details";

export const setSoldSeats = createAction('setSoldSeats', props<{soldSeats: SoldSeats}>())
export const setSeatDetails = createAction('setSeatDetails', props<{seatDetails: SeatDetails}>())
export const setSpecificSoldSeatToTrue = createAction('setSpecificSoldSeatToTrue', props<{seatId: number}>())
export const removeSpecificSoldSeat = createAction('setSpecificSoldSeatTo', props<{seatId: number}>())
export const seatDeleting = createAction('seatDeleting', props<{seatId: number}>())
export const seatDeleted = createAction('seatDeleted', props<{seatId: number}>())
