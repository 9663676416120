import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddOn } from 'src/app/interfaces/State/CartItem/add-on';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { Ticket } from 'src/app/interfaces/State/CartItem/ticket';
import { Tickets } from 'src/app/interfaces/State/CartItem/tickets';
import { CartService } from 'src/app/services/Cart/cart.service';
import { Fixture } from 'src/app/interfaces/State/CartItem/fixture';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { SelectedAddOn } from 'src/app/interfaces/State/CartItem/selected-add-on';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-addon',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
    LocalizeRouterModule,
  ],
  templateUrl: './addon.component.html',
  styleUrls: ['./addon.component.scss'],
})
export class AddonComponent {
  @Input() addon: AddOn = {} as AddOn
  @Input() last: Boolean = false
  @Input() ticket: Tickets = {} as Tickets
  @Input() fixture: Fixture = {} as Fixture

  public showDescription: boolean = false
  public isProcessing: boolean = false
  public isProcessingDown: boolean = false

  constructor(
    private _cartService: CartService,
    private _alertService: AlertService,
    private _translateService: TranslateService,  
  ) {
  }

  public async addToCart() {
    this.isProcessing = true

    const res = await this._cartService.addAddOn(this.ticket.seat_id, this.fixture.id, this.addon.uuid)

    this.isProcessing = false

    /* handle error */
    if (res.error) {
      const errorHappenedText = this._translateService.instant('general.error-happened');

      this._alertService.error(errorHappenedText, res.error)
    }
  }

  public async removeFromCart() {
    this.isProcessingDown = true

    const res = await this._cartService.removeAddOn(this.ticket.seat_id, this.fixture.id, this.addon.uuid)

    this.isProcessingDown = false

    /* handle error */
    if (res.error) {
      const errorHappenedText = this._translateService.instant('general.error-happened');

      this._alertService.error(errorHappenedText, res.error)
    }
  }

  public getNumberOfAddons() {
    let number = 0;

    const addonUuid = this.addon.uuid

    this.ticket.selected_add_ons.map(function (selectedAddon: SelectedAddOn) {
      if (selectedAddon.addon == addonUuid) {
        number++;
      }
    })

    return number
  }
}
