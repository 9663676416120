<div class="mb-[20px] sm:gap-3 sm:flex sm:flex-col" *ngFor="let flexTicketPurchase of flexTicketPurchaseObject.flex_tickets; trackBy: trackByFlexTicketPurchase">
    <div class="justify-between flex px-0 py-5 sm:flex-col sm:pt-0 sm:hidden">
        <a [routerLink]="'/flex/' + flexTicketPurchaseObject.details.uuid" 
            class="flex justify-between items-center text-white  sm:w-full max-w-[40%] sm:max-w-full sm:min-w-0">
            <div class="px-2 ">
                <p class="text-[15px] font-black text-left ">{{ flexTicketPurchaseObject.details.title }}</p>
                <p class="text-sm text-left ">{{ flexTicketPurchaseObject.details.description  }}</p>
            </div>
        </a>
        <div class="w-[60%] sm:w-full px-2 sm:px-0 sm:mt-4">
            <p class="font-black text-[15px] text-white text-right sm:text-center">{{ flexTicketPurchase.details.personal_details }}</p>
            <p class="text-[15px] text-white text-right sm:text-center">{{ flexTicketPurchase.title }}</p>
            <p class="text-[15px] text-white text-right sm:text-center">{{ flexTicketPurchase.price }}</p>
        </div>
    </div>
    <ul class="flex gap-[20px] flex-col">
        <li class="flex flex-col p-5 relative bg-white rounded-[10px]" *ngFor="let ticket of flexTicketPurchase.tickets; trackBy: trackByFlexTicket">
            <p class="hidden sm:inline-block mx-auto bg-[var(--red)] text-white font-bold rounded-[10px] px-3 py-1 mb-3 text-sm">{{ flexTicketPurchaseObject.details.title }}</p>
            
            <!-- FIXTURE DETAILS-->
            <div class="mb-4 pb-2 border-b border-b-slate-100">
                <a [routerLink]="['/fixture/' + ticket.fixture.slug | localize]" *ngIf="isFixtureTypes(ticket.fixture, fixtureTypes().MATCH)" class="flex justify-between select-none items-center">
                    <img class="h-[40px]" [src]="ticket.fixture.home_team_logo" [alt]="'Logó'"/>
                    <div class="px-2">
                        <p class="text-[15px] font-black text-center">{{ ticket.fixture.teams }}</p>
                        <p class="text-[11px] text-slate-400 text-center ">{{ ticket.fixture.date }} ({{ ticket.fixture.competition }}, {{ ticket.fixture.stadium}})</p>
                    </div>
                    <img class="h-[40px]" [src]="ticket.fixture.away_team_logo" [alt]="'Logó'"/>
                </a>
            </div>
            <!-- FIXTURE DETAILS-->
            
            <!-- MOBILE -->
            <div class="hidden sm:block">
                <div class="flex justify-between items-center mb-[10px]">
                    <p class="text-sm font-bold">{{ ticket.title }}</p>

                    <!-- INVOICE ACTION -->
                    <ng-container *ngIf="shouldIssueInvoice">
                        <span *ngIf="!isInvoiceIssued && !ticket.is_invoice_issued && !mapOnly" class="text-[13px] text-[#a0a0a0] text-right" [innerHTML]="'my-ticket.invoice-issue-in-progress' | translate"></span>
                        <a target="_blank" *ngIf="(isInvoiceIssued || ticket.is_invoice_issued) && !mapOnly" [href]="invoiceUrl(ticket)" class="text-[13px] text-[#a0a0a0] underline">{{ 'my-ticket.preview-invoice' | translate }}</a>
                    </ng-container>
                    <!-- INVOICE ACTION -->

                </div>
                <div class="justify-between flex">
                    <div class="max-w-[50%]">
                        <p class="text-[13px] text-[#a0a0a0]" *ngIf="ticket.personal_details.array?.name || ticket.personal_details.array?.first_name">{{ ticket.personal_details.array?.name }} {{ ticket.personal_details.array?.first_name }}</p>
                        <p class="text-[13px] text-[#a0a0a0] -mt-[4px]" *ngIf="ticket.personal_details.array?.birthdate">({{ ticket.personal_details.array?.birthdate }})</p>
                    </div>
                    <div *ngIf="false && isCustomer()">
                        <img class="cursor-pointer max-w-[129px]" (click)="getAppleWalletPass(ticket.seat_id)" src="/assets/images/add-to-apple-wallet.png" alt="Apple Wallet-hoz adás">
                    </div>
                </div>
                <a *ngIf="!ticket.has_pending_transfer" 
                    [routerLink]="mobileTicketUrl(ticket)" 
                    [queryParams]="queryString" 
                    class="mt-[20px] w-full font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center" 
                    >
                    <span *ngIf="!isDownloading(ticket)" >{{ 'my-ticket.open-e-ticket' | translate }}</span>
                    <div *ngIf="isDownloading(ticket)" class="h-[19px]">
                        <app-spinner />
                    </div>
                </a>

                <!-- CANCEL TRANSFER -->
                <span *ngIf="ticket.has_pending_transfer">
                    <p class="text-center text-sm text-[#a0a0a0] mt-2"
                    [innerHTML]="'my-ticket.transfer-in-progress' | translate:{email: ticket.pending_transfers.email}"></p>
                    <div class="mt-4">
                        <app-button [isProcessing] = "isRequesting.includes(ticket.uuid!)" (click)="rekove(ticket)">{{ 'my-ticket.revoke' | translate }}</app-button>
                    </div>
                </span>
                <!-- CANCEL TRANSFER -->
            </div>
            <!-- DESKTOP -->

            <div class="flex justify-between sm:hidden">
                <div>
                    <p class="text-sm font-bold sm:mb-[10px] mb-[2px]">{{ ticket.title }} <span *ngIf="isCashier()">({{ 'my-ticket.barcode' | translate }}: {{ticket.identifier }})</span></p>
                    <p class="text-[13px] block sm:hidden mb-[2px]">{{ ticket.price }}</p>
                    <p class="text-[13px] block sm:hidden">{{ ticket.type }}</p>

                    <!-- INVOICE ACTION-->
                    <ng-container *ngIf="shouldIssueInvoice">
                        <span *ngIf="!isInvoiceIssued && !ticket.is_invoice_issued" class="text-[13px] text-gray-400 block sm:hidden mt-3">
                            {{ 'my-ticket.preview-invoice-desktop' | translate }}
                        </span>
                        <a *ngIf="(isInvoiceIssued || ticket.is_invoice_issued)" target="_blank" [href]="invoiceUrl(ticket)" class="text-[13px] block sm:hidden underline mt-3">{{ 'my-ticket.preview-invoice' | translate }}</a>
                    </ng-container>
                    <!-- INVOICE ACTION-->
                </div>
                <div class="items-center flex">
                    <p class="text-[13px]">{{ ticket.personal_details.short }}</p>
                </div>
                <div  class="items-center flex">
                </div>
                <div class="flex justify-center items-center gap-3" >  
                    <!-- APPLE WALLET -->
                    <img class="cursor-pointer max-w-[155px]" *ngIf="false && isCustomer()" (click)="getAppleWalletPass(ticket.seat_id)" src="/assets/images/add-to-apple-wallet.png" alt="Apple Wallet-hoz adás"> 
                    
                    <!-- TICKET DOWNLOAD -->
                    <button 
                        *ngIf="ticket.is_document_generated"
                        type="button"
                         class="w-[200px] font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center" 
                        (click)="downloadTicket(ticket)">
                        <span *ngIf="!isDownloading(ticket)">{{ 'my-ticket.download-ticket' | translate }}</span>
                        <div *ngIf="isDownloading(ticket)" class="h-[19px]">
                            <app-spinner />
                        </div>
                    </button>

                    <!-- TICKET GENERATING -->
                    <span *ngIf="!ticket.is_document_generated" class="items-center px-[19px] flex flex-col gap-2" >
                        <div>
                            <div class="pdf-loader"></div>
                            <span class="font-bold text-[13px] text-[var(--red)]">{{ 'my-ticket.ticket-in-progress' | translate }}</span>
                        </div>
                    </span>

                    <!-- TICKET PRINT -->
                    <button type="button" *ngIf="isCashier()" class="font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center" (click)="printTicket(ticket.seat_id)">
                        <span *ngIf="!isPrinting(ticket.seat_id)">{{ 'my-ticket.print-ticket' | translate }}</span>
                        <div *ngIf="isPrinting(ticket.seat_id)" class="h-[19px]">
                            <app-spinner />
                        </div>
                    </button>
                </div>
            </div>
        </li> 
    </ul>
</div>
