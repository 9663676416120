import { Injectable } from '@angular/core';
import { AlertService } from '../Alert/alert.service';
import { AuthService } from '../Auth/auth.service';
import { Store } from '@ngrx/store';
import { PersonalDetails } from 'src/app/interfaces/State/MyTickets/personalDetails';

declare const citizen: any;

/// human-readable text for seating position
/// or some other title data below fixture name
type TitleOrSeat = { title?: undefined; seat: string} | { title: string; seat?: undefined };

export type TicketData = TitleOrSeat & {
  fixture: string;
  barcodeData: string;
  seat_id: number;
  receiptText?: string;
  /// human-readable ticket type
  type: string;
  price: string;
  competition: string;
  datetime: string;
};

@Injectable({
  providedIn: 'root'
})
export class TicketPrinterService {
  public printerAddress: string = "http://192.168.0.100:8080";
  private static font: string = "S50";

  private printerObj: any = new citizen.LabelPrint();
  constructor(
    private _alertService: AlertService,
    private _store: Store,
  ) {
    this.setPrinterSettings();
   }

  static smallFont(design: any, data: string, x: number, y: number)
  {
    design.drawTextDLFont(
      data,											//string data
      citizen.LabelConst.CLS_ENC_CDPG_IBM852,			//encoding
      TicketPrinterService.font,		//int font
      citizen.LabelConst.CLS_RT_LEFT90,				//int rotation
      1,												//int hexp
      1,												//int vexp
      8,			//int size
      x,												//int x
      y
    );
  }

  static mediumSmallFont(design: any, data: string, x: number, y: number)
  {
    design.drawTextDLFont(
      data,											//string data
      citizen.LabelConst.CLS_ENC_CDPG_IBM852,			//encoding
      TicketPrinterService.font,		//int font
      citizen.LabelConst.CLS_RT_LEFT90,				//int rotation
      1,												//int hexp
      1,												//int vexp
      10,			//int size
      x,												//int x
      y
    );
  }

  static mediumFont(design: any, data: string, x: number, y: number)
  {
    design.drawTextDLFont(
      data,											//string data
      citizen.LabelConst.CLS_ENC_CDPG_IBM852,			//encoding
      TicketPrinterService.font,		//int font
      citizen.LabelConst.CLS_RT_LEFT90,				//int rotation
      1,												//int hexp
      1,												//int vexp
      12,			//int size
      x,												//int x
      y
    );
  }

  static largeFont(design: any, data: string, x: number, y: number)
  {
    design.drawTextDLFont(
      data,											//string data
      citizen.LabelConst.CLS_ENC_CDPG_IBM852,			//encoding
      TicketPrinterService.font,		//int font
      citizen.LabelConst.CLS_RT_LEFT90,				//int rotation
      1,												//int hexp
      1,												//int vexp
      14,			//int size
      x,												//int x
      y
    );
  }

  static drawBarcode(design: any, data: TicketData, x:number , y:number)
  {
    design.drawBarCode(
      data.barcodeData,
      citizen.LabelConst.CLS_BCS_CODE128, // barcode type
      citizen.LabelConst.CLS_RT_NORMAL,// Rotation
      6,// Thick bar width
      2, // Narrow bar width
      50, // height of barcode
      x,
      y,
      citizen.LabelConst.CLS_BCS_TEXT_SHOW
    );
  }
  static drawQR(design: any, data: TicketData, x:number , y:number)
  {
    design.drawQRCode(
      data.barcodeData,
      citizen.LabelConst.CLS_ENC_CDPG_IBM852, // barcode type
      citizen.LabelConst.CLS_RT_NORMAL,// Rotation
      6,// Magnification
      citizen.LabelConst.CLS_QRCODE_EC_LEVEL_H, // error correction level L M Q H
      x,
      y
    );
  }
  private setPrinterSettings()
  {
    this.printerObj.setMeasurementUnit(citizen.LabelConst.CLS_UNIT_INCH);
    this.printerObj.setContinuousMediaLength(612);
    this.printerObj.setVerticalOffset(0);
    this.printerObj.setLabelSensor(citizen.LabelConst.CLS_SELSENSOR_REFLECT);
    // set Latin-2 code page for proper acute character printing
    const latin2SymbolSetCmd = [0x02, 0x79, 0x53, 0x45, 0x32, 0x0D, 0x0A];// [02] y SE2 
    //Set send error callback function
    this.printerObj.OnError = this.onSendError;

    this.printerObj.messageID('0000001');   
    this.printerObj.sendData(this.printerAddress, latin2SymbolSetCmd);

  }

  public async printTicket(ticketData: TicketData, personal?: PersonalDetails) {
    //Create an LabelDesign object
    let design = new citizen.LabelDesign();
    //Register design of label
    TicketPrinterService.mediumFont(design, ticketData.competition + ' (' + ticketData.datetime + ')', 200, 0);
    if(ticketData.receiptText?.length)
    {
      TicketPrinterService.smallFont(design, ticketData.receiptText, 200, 265);
    }
    TicketPrinterService.largeFont(design, ticketData.fixture, 225, 0);
    TicketPrinterService.mediumFont(design, ticketData.seat ?? ticketData.title, 250, 0);
    TicketPrinterService.mediumFont(design, `${ticketData.type}: ${ticketData.price} (A jegy ára 21,26% ÁFA-t tartalmaz!)`, 275, 0);
    if(personal && personal.name?.length && personal.birthdate?.length && personal.birthplace?.length && personal.birth_country?.length && personal.first_name?.length)
    {
      TicketPrinterService.mediumFont(design, `${personal.name} ${personal.first_name} (${personal.birthdate}, ${personal.birthplace}, ${personal.birth_country})`, 300, 0);
    }
    TicketPrinterService.drawBarcode(design, ticketData, 40, 660);
    TicketPrinterService.drawQR(design, ticketData, 215, 510);
    //Set response receive callback function
    this.printerObj.OnReceive =  (res: any, xml: any) => {
      let msg:string = "";
      if(res.ResponseCode == 'OK'){
        msg = ticketData.fixture + '\n' + ticketData.seat + '\n\n';
      }
      else{
        msg = 'Print failure!\n\n';
        msg += ' Code: ' + res.ErrorCode + '\n';
        msg += ' Description: ' + res.Description + '\n\n';
      }
        msg += ' RequestID: ' + res.RequestID + '\n';
      if(res.ResponseCode == 'OK'){
        this._alertService.success('Nyomtatás sikeres', msg);
        return;
      } 
      this._alertService.error('Nyomtatás sikertelen', msg, true);
    };
    //Set RequestMessageID
    this.printerObj.messageID(ticketData.seat_id);
    //print.printerCheck(printerAddress);
    this.printerObj.print(this.printerAddress, design, 1);
    console.log(design, print);
    console.log('Printing ticket..', ticketData);
  };

  onSendError(res:any) {
    return ;
    let msg = 'Send failure!\n\n';
    msg += ' status: ' + res.status + '\n';
    msg += ' responseText: ' + res.responseText + '\n';
    this._alertService.error('Nyomtató nem észlelhető!', msg, true);

  }
}
