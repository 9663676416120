import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AdvertisementType } from 'src/app/enums/advertisement/advertisementTypes';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { State } from 'src/app/interfaces/State/MySeasonPasses/state';
import { AdvertisementService } from 'src/app/services/AdvertisementService/advertisement.service';
import { MySeasonPassService } from 'src/app/services/MySeasonPass/my-season-pass.service';

@Component({
  selector: 'app-my-season-passes',
  templateUrl: './my-season-passes.component.html',
  styleUrls: ['./my-season-passes.component.scss']
})
export class MySeasonPassesComponent {
  public seasonPasses: State = {
    current: {},
    expired: {},
  }
  public advertisementUrl?: string

  private _subscription?: Subscription

  public auth: Auth = {} as Auth

  public mapOnly: boolean = false

  constructor(
    private _mySeasonPassService: MySeasonPassService,
    private _store: Store,
    private _route: ActivatedRoute,
    private _advertisementService: AdvertisementService,
  ) {
    this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false
  }

  ngOnInit(): void {
    this._mySeasonPassService.getCurrentSeasonPasses()
    this._mySeasonPassService.getExpiredSeasonPasses()

    this._advertisementService.getAdvertisement(AdvertisementType.MYTICKETS)
      .then((res) => {
        this.advertisementUrl = res.data['url']
      })

    this._subscription = this._store.subscribe((state: any) => {
      this.seasonPasses = state.mySeasonPasses
      this.auth = state.auth
    })
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }

  public hasCurrentSeasonPasses() {
    return this.seasonPasses.current ? Object.keys(this.seasonPasses.current).length : false
  }

  public hasExpiredSeasonPasses() {
    return this.seasonPasses.expired ? Object.keys(this.seasonPasses.expired).length : false
  }

}
