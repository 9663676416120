import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { UserType } from 'src/app/enums/user/userType';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { MySeasonPassIndex } from 'src/app/interfaces/State/MySeasonPasses';
import { SeasonPass } from 'src/app/interfaces/State/MySeasonPasses/seasonPass';
import { SeasonPassPurchase } from 'src/app/interfaces/State/MySeasonPasses/seasonPassPurchase';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { HelperService } from 'src/app/services/Misc/Helper/helper.service';
import { MySeasonPassService } from 'src/app/services/MySeasonPass/my-season-pass.service';
import { TransferService } from 'src/app/services/Transfer/transfer.service';

@Component({
  selector: 'app-my-season-pass',
  templateUrl: './my-season-pass.component.html',
  styleUrls: ['./my-season-pass.component.scss']
})
export class MySeasonPassComponent {
  @Input() seasonPassWrapperObject: MySeasonPassIndex = {} as MySeasonPassIndex
  @Input() auth: Auth = {} as Auth
  @Input() isInvoiceIssued?: Boolean = false
  @Input() shouldIssueInvoice?: boolean = true

  public downloading: Array<number> = []
  public printing: Array<number> = []
  public showBackToMatch: Boolean = false
  public pdfUrl?: string
  public mapOnly: boolean = false
  error: string = ''
  success: boolean = false
  isRequesting: Array<string> = []

  constructor(
    private _mySeasonPassService: MySeasonPassService,
    private sanitizer: DomSanitizer,
    private _alertService: AlertService,
    private _route: ActivatedRoute,
    private _transferService: TransferService,
    private _translateService: TranslateService,
    private _localizeRouterService: LocalizeRouterService,
    private _helperService: HelperService,
  ) {
    this.showBackToMatch = this._route.snapshot.queryParamMap.get('only-map') ? false : true
    this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false
  }

  ngOnInit() {
  }

  public async printSeasonPass(seatId: number) {
    ;
  }

  public isDownloading(seatId: number) {
    return this.downloading.includes(seatId)
  }

  public printIdentifier(seasonPassPurchase: SeasonPassPurchase) {
    return this.sanitizer.bypassSecurityTrustHtml(seasonPassPurchase.identifier_src)
  }

  public async getAppleWalletPass(seatId: number) {
    const res = await this._mySeasonPassService.getAppleWalletPass(this.seasonPassWrapperObject.details.slug, seatId)
  }

  public isCashier(): boolean {
    return this.auth.user.type == UserType.CASHIER
  }

  public isCustomer(): boolean {
    return this.auth.user.type == UserType.CUSTOMER
  }

  public isPrinting(seatId: number) {
    return this.printing.includes(seatId)
  }

  public mobileTicketUrl(seasonPassPurchase: SeasonPassPurchase) {
    return this._helperService.translateRoute(`my-tickets/season-pass/${this.seasonPassWrapperObject.details.slug}/ticket/${seasonPassPurchase.uuid}`);
 }

  get queryString() {
    return this._route.snapshot.queryParams
  }

  public invoiceUrl(seasonPassPurchase: SeasonPassPurchase) {
    let queryString = ''

    if(this.mapOnly) {
      queryString = '?only-map=1'
    }

    return this._helperService.translateRoute(`order/${seasonPassPurchase.order_id}/invoice${queryString}`);
  }

  public async downloadSeasonPass(seatId: number, isMobile: boolean = false) {
    if (this.downloading.includes(seatId)) return;

    this.downloading.push(seatId)

    try {
      const res = await this._mySeasonPassService.downloadSeasonPass(this.seasonPassWrapperObject.details.slug, seatId)

      const index = this.downloading.indexOf(seatId, 0);

      if (index > -1) {
        this.downloading.splice(index, 1);
      }

      if (res.success) {
        if (isMobile) {
          this.pdfUrl = res.data.url
        } else {
          window.open(res.data.url, '_blank');
        }
      }
    } catch(e: any) {
      const errorHappenedText = this._translateService.instant('general.error-happened');

      this._alertService.error(
        errorHappenedText,
        e.error.error ?? '',
      )
      
      const index = this.downloading.indexOf(seatId, 0);
      if (index > -1) {
        this.downloading.splice(index, 1);
      }
    }
  }

  async revoke(seasonPass: SeasonPassPurchase) {
    if (this.isRequesting.includes(seasonPass.uuid!)) return;

    this.isRequesting.push(seasonPass.uuid!)

    this.error = ""

    const res = await this._transferService.revokeSeasonPass(this.seasonPassWrapperObject.details.slug, seasonPass.uuid!)

    const index = this.isRequesting.indexOf(seasonPass.uuid!);
    if (index > -1) { // Only splice if the element exists
      this.isRequesting.splice(index, 1); // Removes one element at the specified index
    }

    if (res === true) {
      this.success = true
      const revokeSuccessfulText = this._translateService.instant('my-season-pass.revoke-successful');
      this._alertService.success(revokeSuccessfulText, undefined, false)

      return;
    }

    this.success = false
    this.error = res.error
    this._alertService.error(undefined, res.error)
  }

  public trackBySeasonPass(index: number, item: any): any {
    return 'season-pass-' + item.uuid
  }
}
