import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PurchaseType } from 'src/app/enums/stadium/purchase-type';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { Tickets } from 'src/app/interfaces/State/CartItem/tickets'
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';
import { ModalService } from 'src/app/services/Modal/modal.service';
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent {
  @Input() ticket: Tickets = {} as Tickets
  @Input() fixtureId: string = ''
  @Input() cart: Cart = {} as Cart
  @Input() key!: number

  public isRequesting: boolean = false

  constructor(
    private _cartService: CartService,
    private _alertService: AlertService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
  ) {
  }

  showPersonalDetailModal() {
    this._modalService.showCartTicketPersonalDetails({
      ticketId: this.ticket.seat_id, 
      key: this.key,
      fixtureId : this.fixtureId
    })
  }

  showTicketTypeEditModal() {
    this._modalService.showTicketTypeEditModal({ticket: this.ticket, fixtureId: this.fixtureId})
  }

  async removeSeat(seatId: number, fixtureId: string): Promise<void> {
    if (!seatId) return
    this.isRequesting = true
    const res = await this._cartService.removeSeat(seatId, fixtureId, PurchaseType.TICKET)
    this.isRequesting = false

    /* handle error */
    if (res.error) {
      const errorHappenedText = this._translateService.instant('general.error-happened');
      const removingSeatFailedText = this._translateService.instant('stadium.seat.removing-seat-failed');

      this._alertService.error(errorHappenedText, res.error.error ?? removingSeatFailedText)
    }
  }
}
