<div class="container w-full h-full">
    <div class="rounded-[10px] px-0 max-w-full h-[98%]">
        <button (click)="closeFn()" class="bg-[var(--primary-color)] text-center block mx-auto text-white w-full font-bold">Vissza</button>

        <div class="bg-white rounded-[10px] h-full flex justify-center p-[5px] flex-col">
            <p class="text-center" *ngIf="!myUrl">{{ 'my-ticket.loading' | translate }}</p>
            <iframe id="invoice" *ngIf="myUrl" [src]="myUrl" class="h-full"></iframe>
        </div>

        <ng-container *ngIf="errorMsg">
            <div class="bg-white rounded-[10px] p-6 mt-6">
                <p class="text-center" [innerHTML]="errorMsg"></p>
            </div>
        </ng-container>
    </div>
</div>