<li [ngClass]="{'border-b border-b-[#d0d0d0]': !last}">
    <div class="grid grid-cols-[50px_70%_calc(100%-70%-50px)] py-5 md:flex md:flex-col md:gap-2">
        <div class="flex items-center md:mx-auto">
            <img [src]="addon.logo" [alt]="addon.title" class="w-[50px]">
        </div>
        <div class="pl-4 flex justify-center flex-col pr-4 sm:p-0">
            <p class="font-bold">{{ addon.title }}</p>
            <p>{{ addon.subtitle }}</p>
            <p class="text-[12px] text-[#888888]">{{ addon.price }}</p>
        </div>
        <div class="flex flex-col justify-center">
            <!-- NOT AVAILABLE -->
            <app-button [disabled]="true" *ngIf="!addon.available && !getNumberOfAddons()" [style]="'addon-disabled'" type="button">
                {{ 'general.not-available' | translate }}
            </app-button>

            <!-- AVAILABLE -->
            <app-button *ngIf="addon.available && !getNumberOfAddons()" [isProcessing]="isProcessing"
                [style]="'addon-available'" (click)="addToCart()" type="button">{{ addon.price }}</app-button>

            <!-- INCREASE / DECREASE -->
            <div class="flex items-center justify-center gap-3 h-[47.5px]" *ngIf="getNumberOfAddons()" >
                <!-- REMOVE -->
                <app-button 
                    [isProcessing]="isProcessingDown"
                    [disabled]="isProcessingDown || isProcessing"
                    [style]="'addon-quantity'" 
                    (click)="removeFromCart()" 
                    type="button"><span class="mx-auto text-[#575555]">-</span></app-button>
                <!-- REMOVE -->
                
                <span class="font-bold text-[#575555] text-[14px]">{{ getNumberOfAddons() }}</span>

                <!-- ADD -->
                <app-button 
                    [isProcessing]="isProcessing"
                    [disabled]="isProcessingDown || isProcessing"
                    [style]="'addon-quantity'" 
                    (click)="addToCart()" 
                    type="button"><span class="mx-auto text-[#575555]">+</span></app-button>
                <!-- ADD -->
            </div>

            <!-- SHOW DESCRIPTION -->
            <button class="mx-auto underline mt-3 text-[#9e9e9e] text-[12px]" type="button"
                (click)="showDescription = !showDescription">{{ 'addon.more-info' | translate }}</button>
        </div>
    </div>
    <div *ngIf="showDescription" [innerHTML]="addon.description" class="pb-5 text-sm text-[#9e9e9e]"></div>

</li>