<div class="container pt-10 sm:pt-0">
    <div class="flex justify-between sm:flex-col">
        <div class="mb-5 w-[75%] sm:w-full">
            <!-- LOADING -->
            <div *ngIf="isLoading() && !waitingOver" class="mt-20 block sm:hidden">
                <div class="mb-3" *ngFor="let loader of [].constructor(5); let i = index;">
                    <app-skeleton-loader [row]="4.203125" classList="rounded-[10px]" />
                </div>
            </div>
            <div *ngIf="isLoading() && !waitingOver" class="mt-[83px] hidden sm:flex flex-col gap-[20px]">
                <div *ngFor="let loader of [].constructor(5); let i = index;">
                    <app-skeleton-loader [row]="4.703125" classList="rounded-[10px]" />
                </div>
            </div>
            <!-- LOADING -->

            <!-- ERROR -->
            <p *ngIf="isLoading() && waitingOver" class="text-center text-[var(--red)] font-bold flex flex-col p-5 relative bg-white rounded-[10px]">
                {{ 'my-tickets.error-happened' | translate}}
            </p>
            <!-- ERROR -->

            <!-- EMPTY STATE -->
            <div *ngIf="!isLoading() && 
            !hasCurrentSeasonPasses() && 
            !hasCurrentTickets()"
            class="flex flex-col p-5 relative bg-white rounded-[10px]"
            >
            <p>{{ 'my-tickets.no-tickets-purchased' | translate }}</p>

            </div>
            <!-- EMPTY STATE -->

            <div *ngIf="hasCurrentSeasonPasses()">
                <div id="current-tickets" class=" mb-7">
                    <div class=" mb-3" *ngFor="let seasonPass of seasonPasses?.current | keyvalue">
                        <app-my-season-pass [auth]="auth"
                            [seasonPassWrapperObject]="seasonPass.value"></app-my-season-pass>
                    </div>
                </div>
            </div>
            <div *ngIf="hasExpiredSeasonPasses()"> 
                <h2 class="text-white text-[15px] font-bold mb-5">{{ 'my-season-passes.my-season-passes' | translate }}</h2>
                <div id="current-tickets" class=" rounded-[10px] mb-3">
                    <div class="rounded-[10px] mb-3" *ngFor="let seasonPass of seasonPasses?.expired | keyvalue ">
                        <app-my-season-pass [auth]="auth"
                            [seasonPassWrapperObject]="seasonPass.value"></app-my-season-pass>
                    </div>
                </div>
            </div>

            <div *ngIf="hasCurrentTickets()">
                <div id="current-tickets" class=" mb-7">
                    <div class=" mb-3" *ngFor="let fixture of fixtures?.upcoming ">
                        <app-my-ticket [auth]="auth" [fixtureWrapperObject]="fixture"></app-my-ticket>
                    </div>
                </div>
            </div>
            <div *ngIf="hasExpiredTickets()"> 
                <h2 class="text-white text-[15px] font-bold mb-5">{{ 'my-tickets.my-past-tickets' | translate }}</h2>
                <div id="current-tickets" class=" rounded-[10px] mb-3">
                    <div class="rounded-[10px] mb-3" *ngFor="let fixture of fixtures?.past ">
                        <app-my-ticket [auth]="auth" [fixtureWrapperObject]="fixture"></app-my-ticket>
                    </div>
                </div>
            </div>
        </div>
        <section class="w-[20%] sm:w-full" *ngIf="!mapOnly && advertisementUrl">
            <img [src]="advertisementUrl" [alt]="'homepage.advert' | translate" class="rounded-[20px]">
        </section>
    </div>
</div>