import { Injectable } from '@angular/core';
import { Client, Environment } from 'src/configurations/configuration-map';
import { environment } from 'src/environments/environment';
import { CookieService } from '../../Cookie/cookie.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ConfigService } from '../Config/config.service';
import { takeWhile } from 'rxjs';
import { getDefaultLanguage } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  public constructor(
    private _cookieService: CookieService,
    private _localizeRouterService: LocalizeRouterService,
    private _configService: ConfigService,
  ) {

  }

  public getClient(): Client {
    return environment.environment == 'development' ? environment.client as Client : window.location.hostname.split('.')[0] as Client
  }

  public getEnvironment(): Environment {
    return environment.environment as Environment
  }

  /* get current language */
  public async getCurrentLanguage(): Promise<string> {
    if(this._localizeRouterService.parser.currentLang) {
      return Promise.resolve(this._localizeRouterService.parser.currentLang)
    }

    return await this.getLocationLanguage() ?? Promise.resolve('en');
  }

  private async getLocationLanguage(): Promise<string> 
  {
    const queryParamSplit = window.location.pathname.slice(1).split(/[\?;]/);

    let pathSlices = [] as any;
    if (queryParamSplit.length > 0) {
        pathSlices = queryParamSplit[0].split('/');
    }
    
    let isLanguageAvailable = false

    if(pathSlices.length) {
      isLanguageAvailable = await this._configService.isLanguageAvailable(this.getClient(), this.getEnvironment(), pathSlices[0]) as boolean

      if(isLanguageAvailable) {
        return Promise.resolve(pathSlices[0])
      }
    }

    return Promise.resolve(getDefaultLanguage(this.getClient()))
  }

  public translateRoute(path: string) {
    const p = path.startsWith('/') ? path : '/' + path

    return this._localizeRouterService.translateRoute(p)
  }

  /* get browser language */
  public getBrowserLanguage(): string | null {
    if (!navigator?.language) return null

    return (navigator.language || '').split('-')[0];
  }
}
