import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { Store } from '@ngrx/store';
import { FlexService } from 'src/app/services/Flex/flex.service';
import { FlexRule } from 'src/app/interfaces/State/Flex/FlexRule';
import { FlexFixture } from 'src/app/interfaces/State/Flex/FlexFixture';
import { FlexFixtureFixture } from 'src/app/interfaces/State/Flex/FlexFixtureFixture';
import { FlexFixtureCategory } from 'src/app/enums/flex/flexFixture/flexFixtureCategory';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CookieService } from 'src/app/services/Cookie/cookie.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HelperService } from 'src/app/services/Misc/Helper/helper.service';
import { CheckComponent } from 'src/app/components/assets/icons/check/check.component';
import { ConfigService } from 'src/app/services/Misc/Config/config.service';
import { CartError } from 'src/app/enums/cart/errors';
import { CartService } from 'src/app/services/Cart/cart.service';
import { AlertComponent } from 'src/app/components/assets/icons/alert/alert.component';

@Component({
  selector: 'app-flex',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
    LocalizeRouterModule,
    CheckComponent,
    AlertComponent,
  ],
  templateUrl: './flex.component.html',
  styleUrls: ['./flex.component.scss']
})
export class FlexComponent {
  public isLoadingInitially: boolean = true
  public isRequesting: boolean = false
  public flexRules?: Array<FlexRule>
  public flexFixtures?: Array<FlexFixture>
  public selectedFixtures: Array<FlexFixture> = []
  public redirecting: boolean = false
  public static cookieKey: string = 'flex-selected-fixtures'
  public showFlex: boolean = false
  public cover: string | null = null
  public title: string | null = null
  public subtitle: string | null = null
  public mapOnly: boolean

  constructor(
    private _flexService: FlexService,
    private _alertService: AlertService,
    private _cookieService: CookieService,
    private _helperService: HelperService,
    private _translateService: TranslateService,
    private _router: Router,
    private _configService: ConfigService,
    private _cartService: CartService,
    private _modalService: ModalService,
    private _route: ActivatedRoute,
  ) {
    this.showFlex = this._configService.clientConfig()?.flex ?? false

    this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false
  }

  public async ngOnInit() {
    if (!this.showFlex) {
      this.isLoadingInitially = false
      return
    }

    const res = await this._flexService.getFlex()
    this.flexRules = res.data['flex-rules']
    this.flexFixtures = res.data['flex-fixtures']
    this.cover = res.data['settings']['cover']
    this.title = res.data['settings']['title']
    this.subtitle = res.data['settings']['subtitle']
    this.isLoadingInitially = false
  }

  public async next() {
    /* if can not be continued */
    if (!this.canBeContinued()) {
      const selectionErrorText = this._translateService.instant('flex.selection-error');
      this._alertService.error("", selectionErrorText);
      return;
    }

    const dateAlertMatches = this.selectedFixtures.filter((fixture: FlexFixture) => fixture?.fixture?.date_alert === true)
    .map((fixture: FlexFixture) => fixture?.fixture?.teams )

    if (dateAlertMatches.length) {
      const dateAlertMatchesString = dateAlertMatches.join(', ')
      const areYouSureToSave = this._translateService.instant('flex.date-alert-match-selected', {matches: dateAlertMatchesString});
      const next = this._translateService.instant('general.flex_next');
      const cancel = this._translateService.instant('general.cancel');

      this._alertService.confirm(
        areYouSureToSave,
        () => this.processNext(),
        () => this._modalService.hideModal(),
        next,
        cancel,
      )
      return ;
    }

    this.processNext()
  }

  public async processNext() {
    /* redirecting */
    this.redirecting = true

    /* selected fixtures array */
    const selectedFixturesArray = this.selectedFixtures.map((fixture: FlexFixture) => {
      return fixture.fixture.id
    })

    try {
      await this._flexService.validateSelection(this.getValidFlex()?.uuid!, selectedFixturesArray)
    } catch (e: any) {
      if (e.error) {
        /* general error */
        if (e.error.status != CartError.CART_HAS_SAME_FLEX_RULE_WITH_DIFFERENT_MATCHES) {
          const errorHappenedText = this._translateService.instant('general.error-happened');

          this._alertService.error(errorHappenedText, e.error.error ?? '')
          this.redirecting = false
          return;
        }

        this.redirecting = false
        const next = this._translateService.instant('general.flex_next');
        const cancel = this._translateService.instant('general.cancel');

        this._alertService.confirm(
          e.error.error,
          () => this.confirmDelete(this.getValidFlex()?.uuid!, selectedFixturesArray),
          () => { this.redirecting = false },
          next,
          cancel,
        )
        return;
      }

      const errorHappenedText = this._translateService.instant('general.error-happened');

      this._alertService.error(errorHappenedText, e.error.error ?? '')
      this.redirecting = false
      return;
    }

    this.navigate(selectedFixturesArray)
  }

  public async confirmDelete(flexRuleUuid: string, selectedFixturesArray: Array<string>) {
    this.redirecting = true

    const res = await this._cartService.removeSameFlexRuleFromCart(flexRuleUuid)

    /* if error occured */
    if (res.error) {
      this.redirecting = false
      const errorHappenedText = this._translateService.instant('general.error-happened');
      this._alertService.error(errorHappenedText, res.error ?? '')

      return;
    }

    /* navigate */
    this.navigate(selectedFixturesArray)
  }

  public navigate(selectedFixturesArray: Array<string>) {
    const flexUrl = this._helperService.translateRoute("flex/" + this.getValidFlex()?.uuid)

    let queryParams = { 
      'fixtures[]': selectedFixturesArray
    } as object

    if (this.mapOnly) {
      queryParams = {
        ...queryParams,
        'only-map' : 1,
      }
    }

    this._router.navigate([flexUrl], {
      queryParams: queryParams
    });
  }

  public unselect(fixtureSlug: string) {
    this.selectedFixtures = this.selectedFixtures.filter(item => item.fixture.id !== fixtureSlug);
  }

  public select(fixture: FlexFixture) {
    this.selectedFixtures.push(fixture)
  }

  public isSelected(fixtureSlug: string) {
    return this.selectedFixtures.find(fixture => fixture.fixture.id == fixtureSlug)
  }

  public lessSelectedFixtures(rule: FlexRule): Boolean {
    return rule.no_matches > this.selectedFixtures.length
  }

  public validFixtureSelection(rule: FlexRule): Boolean {
    if (rule.no_matches != this.selectedFixtures.length) return false

    const numberOfNormalFixturesToSelect = rule.no_normal_matches
    const numberOfPremiumFixturesToSelect = rule.no_premium_matches

    const normalFixtures = this.selectedFixtures.filter((fixture) => fixture.category == FlexFixtureCategory.NORMAL)
    const premiumFixtures = this.selectedFixtures.filter((fixture) => fixture.category == FlexFixtureCategory.PREMIUM)

    return numberOfNormalFixturesToSelect <= normalFixtures.length && numberOfPremiumFixturesToSelect <= premiumFixtures.length
  }

  public invalidFixtureSelection(rule: FlexRule): Boolean {
    return rule.no_matches == this.selectedFixtures.length && !this.validFixtureSelection(rule)
  }

  public isPremium(fixture: FlexFixture) {
    return fixture.category == FlexFixtureCategory.PREMIUM
  }

  public canBeContinued(): Boolean {
    return this.flexRules?.find((rule) => {
      return this.validFixtureSelection(rule)
    }) ? true : false
  }

  public getValidFlex() {
    return this.flexRules?.find((rule) => {
      return this.validFixtureSelection(rule)
    })
  }

  public client() {
    return this._helperService.getClient()
  }

  public dateAlert(date: string) {
    const dateAlertText = this._translateService.instant('flex.date-alert', { 'date': date });

    this._alertService.info(undefined, dateAlertText)
  }
}
