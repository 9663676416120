import { createReducer, on } from "@ngrx/store";
import { State } from "../interfaces/State/MyAddons/state";
import { SingleFixtureAddon } from "../interfaces/State/FixtureAddon/singleFixtureAddon";
import { clearEAddon, setEAddon } from "../actions/myaddons.actions";

export const initialState = {
    eAddon: {} as SingleFixtureAddon,
} as State

export const MyAddonReducer = createReducer(
    initialState,
    on(setEAddon, (state, {addon}) => ({...state, eAddon : addon})),
    on(clearEAddon, (state,) => ({...state, eAddon : {} as SingleFixtureAddon })),
);